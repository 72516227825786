export default {
    init() {

        $(document).ready(function() {
            // Fade in container
            $('.productie__container').fadeIn();


            // Posts slider.
            let $productionContainer = $('.productie__slide--berichten');
            let $productionSlider = $('.productie__berichten__slider');
            // Slide time per post. 9000
            let $postSlideTime = 9000;

            // User slider
            let $userContainer = $('.productie__slide--collega');
            let $userSlider = $('.collega__slider');
            // Slide time per user. 4000
            // Slide time per user. 26000
            let $userSlideTime = 4000;
            let $usersliderTotalTime = 25800;

            // User slider
            let $calendarContainer = $('.productie__slide--event');
            let $calendarSlider = $('.calendar__slider');
            // Slide time per user. 4000
            let $calendarSlideTime = 4000;
            let $countCalender = $('.calendar__slider .collega__slide').length;
            let $calendarSliderTotalTime;


            // User slider
            let $brithdayContainer = $('.productie__slide--birthday');
            let $brithdaySlider = $('.birthday__slider');
            // Slide time per user. 4000
            let $brithdaySlideTime = 4000;
            let $brithdaysliderTotalTime = 25800;
            // let $countBirthday = $('.birthday__slider .collega__slide').length;
            // console.log('Calender items' + $countBirthday)


            // Init post slider
            $productionSlider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                infinite: false,
                fade: true,
                pauseOnHover: false,
                pauseOnFocus: false,
                pauseOnDotsHover: false,
                draggable: false,
                autoplay: true,
                autoplaySpeed: $postSlideTime,
            });
            $productionSlider.slick('slickPlay');

            // Init post slider
            $userSlider.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: false,
                infinite: false,
                fade: false,
                pauseOnHover: false,
                pauseOnFocus: false,
                pauseOnDotsHover: false,
                draggable: true,
                autoplay: true,
                autoplaySpeed: $userSlideTime,
            });
            $userSlider.slick('slickPause');

            // Init post slider
            $calendarSlider.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: false,
                infinite: false,
                fade: false,
                pauseOnHover: false,
                pauseOnFocus: false,
                pauseOnDotsHover: false,
                draggable: true,
                autoplay: true,
                autoplaySpeed: $calendarSlideTime,
            });
            $calendarSlider.slick('slickPause');

            // Init post slider
            $brithdaySlider.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: false,
                infinite: false,
                fade: false,
                pauseOnHover: false,
                pauseOnFocus: false,
                pauseOnDotsHover: false,
                draggable: true,
                autoplay: true,
                autoplaySpeed: $brithdaySlideTime,
            });
            $brithdaySlider.slick('slickPause');

            function birthdaySlider() {
                $brithdayContainer.animate({ opacity: 1 });
                $calendarContainer.fadeOut();
                $brithdaySlider.slick('slickPlay');
                $('.collega__menu__line__set--third .collega__menu__line__inner').animate({width: '100%'}, {duration: $brithdaysliderTotalTime, easing: 'linear'});
                /* eslint-disable */
                let end = Date.now() + $brithdaysliderTotalTime;
                console.log('end = '+ end);

                // go Buckeyes!
                let colors1 = [ '#c61889' ];
                let colors2 = [ '#28348a' ];

                (function frame() {
                    confetti({
                        particleCount: 1,
                        angle: 60,
                        spread: 55,
                        origin: { x: 0 },
                        colors: colors1
                    });
                    confetti({
                        particleCount: 1,
                        angle: 120,
                        spread: 55,
                        origin: { x: 1 },
                        colors: colors2
                    });

                    if (Date.now() < end) {
                        requestAnimationFrame(frame);
                    }
                }());

                /* eslint-enable */
            }

            $productionSlider.on('afterChange', function() {
                if($productionSlider.slick('slickCurrentSlide') == 4) {
                    $productionSlider.slick('slickPause');
                    setTimeout(function () {
                        $userContainer.animate({ opacity: 1 });
                        $productionContainer.fadeOut();
                        $userSlider.slick('slickPlay');
                        $('.collega__menu__line__set--first .collega__menu__line__inner').animate({width: '100%'}, {
                            duration: $usersliderTotalTime,
                            easing: 'linear',
                        });
                    }, $postSlideTime);
                }
            });

            function calenderActive() {
                if($countCalender < 5) {
                    $calendarSliderTotalTime = 10000;
                } else if ($countCalender > 8) {
                    $calendarSliderTotalTime = 26000;
                    $calendarSlider.slick('slickPlay');
                } else {
                    $calendarSliderTotalTime = $countCalender * 2500;
                    $calendarSlider.slick('slickPlay');
                }

                console.log('time: '+$calendarSliderTotalTime)

                setTimeout(function () {
                    $('.collega__menu__line__set--third .collega__menu__line__inner').animate({width: '100%'}, {duration: $brithdaysliderTotalTime, easing: 'linear'});
                    $('.collega__menu__line__set--second .collega__menu__line__inner').css('opacity', '0');
                    birthdaySlider();
                }, $calendarSliderTotalTime)
            }

            $userSlider.on('afterChange', function() {
                if($userSlider.slick('slickCurrentSlide') == 5) {
                    $userSlider.slick('slickPause');

                    if($countCalender > 0) {
                        setTimeout(function () {
                            $('.collega__menu__line__set--first .collega__menu__line__inner').css('opacity', '0');
                            $calendarContainer.animate({opacity: 1});
                            $userContainer.fadeOut();
                            calenderActive();
                            $('.collega__menu__line__set--second .collega__menu__line__inner').animate({width: '100%'}, {
                                duration: $calendarSliderTotalTime,
                                easing: 'linear',
                            });
                        }, $userSlideTime);
                    } else {
                        setTimeout(function () {
                            $('.collega__menu__line__set--first .collega__menu__line__inner').css('opacity', '0');
                            $calendarContainer.animate({opacity: 1});
                            $('.collega__menu__line__set--second .collega__menu__line__inner').animate({width: '100%'}, {
                                duration: 8000,
                                easing: 'linear',
                            });

                            setTimeout(function () {
                                $('.collega__menu__line__set--second .collega__menu__line__inner').css('opacity', '0');
                                birthdaySlider();
                            }, 8000)
                        }, $userSlideTime)
                    }
                }
            });

            $brithdaySlider.on('afterChange', function() {
                if($brithdaySlider.slick('slickCurrentSlide') == 5) {
                    setTimeout(function () {
                        $('.productie__container').fadeOut();
                        /* eslint-disable */
                        location.reload(false);
                        /* eslint-enable */
                    }, $brithdaySlideTime)
                }
            });

        });


    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

