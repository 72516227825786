export default {
    init: function () {
        // JavaScript to be fired on all pages

        // // Jquery-match-height
        $(function() {
            $('.archive__list__items--related .archive__item__content').matchHeight();
        });

        /* eslint-disable */
        $('.calendar__item--user').mouseenter(function () {
            confetti({
                origin: {
                    // since they fall down, start a bit higher than random
                    y: 0.65
                }
            });
        })
        /* eslint-enable */

        $('.archive__list__more').on('click', function () {
            setTimeout(function () {
                $('.archive__list__items--related .archive__item__content').matchHeight();
            }, 1000)
        })

        if($('.calendar__item--event').length > 0) {
            $('.calender__block__hide__birthdays').show();
        }

        $('.calender__block__hide__birthdays').on('click', function () {
            $('.calendar__item--user').fadeToggle();

            $('.calendar__block').each(function () {
                if($(this).find('.calendar__item--event').length === 0) {
                    $(this).fadeToggle();
                }
            })
        })

        setTimeout(function(){
            $('#onesignal-popover-container.slide-down').css('top', '0');
            $('#onesignal-popover-container').css('opacity', '1');
        }, 2000);

        $('.lwa-rememberme').attr('id', 'dom__rememberme');
        $('.lwa-rememberme').closest('.lwa-submit-links').find('label').attr('for', 'dom__rememberme');

        if ($('body').hasClass('home-data') || $('body').hasClass('category')) {
            $('.archive__list__items__list').infiniteScroll({
                // options
                path: '.navigation .next',
                append: '.archive__item ',
                history: false,
                hideNav: '.navigation',
                scrollThreshold: false,
                button: '.archive__list__more',

            });
        } else if ($('body').hasClass('single-post')) {
            $('.archive__list__items__list').infiniteScroll({
                // options
                path: '.navigation .next',
                append: '.archive__item__related',
                history: false,
                hideNav: '.navigation',
                scrollThreshold: false,
                button: '.archive__list__items--related .archive__list__more',
            });
        }

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
           $('.archive__item__player').attr('poster', 'https://dummyimage.com/1028x683/000/fff')
        }

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        $('[data-fancybox]').fancybox({
            margin : [44,0,22,0],
            thumbs : {
                autoStart : true,
                axis      : 'x',
            },
        })

        jQuery(document).ready(function () {
            $('.sidebar__dropdown').on('click', function () {
                $(this).closest('.sidebar__content').toggleClass('open');
            })

            $('.front-page-data .sidebar__login__form #input_1').focus();

            $('.gfield input').each(function () {
                if ($(this).val() == 'nl_NL') {
                    $(this).val('Nederlands')
                } else if ($(this).val() == 'de_DE'){
                    $(this).val('Deutsch')
                } else if ($(this).val() == 'fr_FR'){
                    $(this).val('français')
                }
            });

            $('.readonly input, .readonly textarea').attr('readonly', 'readonly');

            $('.header__hamburger').on('click', function () {
                $(this).toggleClass('open')
                $('body').toggleClass('menu__open')
            })
        })

        jQuery(document).ready(function () {
            jQuery('.category archive__list__items, .category .archive__sidebar').theiaStickySidebar({
                additionalMarginTop: 50,
            });

            jQuery('.home-data archive__list__items, .home-data .archive__sidebar').theiaStickySidebar({
                additionalMarginTop: 50,
            });

            jQuery('.page content__main, .page .archive__sidebar').theiaStickySidebar({
                additionalMarginTop: 50,
            });

        });

        jQuery(document).ready(function($) {
            if ($(window).width() > 1549) {
                setTimeout(function(){
                    let $firstItem = $('.archive__list__items .archive__item:first-child .archive__gallery').innerHeight();
                    let $extraHeight;

                    if ($firstItem) {
                        $extraHeight = 70;
                    } else {
                        $extraHeight = 0;
                    }
                    $('.archive__sidebar').css('margin-top', $firstItem + $extraHeight);
                }, 300);

            }

            setTimeout(function() {
                $('.archive__list__items').css('opacity', '1');
                $('.archive__sidebar').css('opacity', '1');
            }, 300);


            $('.blog .archive__list__items .archive__item:first-child .archive__item__title').appendTo('.blog .archive__list__items .archive__item:first-child .archive__gallery .archive__gallery__top');
            $('.category .archive__list__items .archive__item:first-child .archive__item__title').appendTo('.category .archive__list__items .archive__item:first-child .archive__gallery .archive__gallery__top');

        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
